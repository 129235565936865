<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogVisibletitle"
    :close-on-click-modal="false"
    width="900px"
  > 
    <template v-if="dialogVisibletitle==='新增菜单'||dialogVisibletitle==='修改菜单'">
      <div class="dp-f">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          status-icon
          label-width="150px"
          class="demo-ruleForm w100"
        >
          <div class="w100 dp-f fw-w">
            <el-form-item label="菜单类型" prop='type'>
              <el-radio-group v-model="ruleForm.type" >
                <el-radio-button :label="item.value" :value="item.value" v-for="(item,ind) in state.menuTypeData" :key="ind">{{item.label}}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上级菜单">
              <el-tree-select v-model="ruleForm.parentId" :data="state.menuData" :render-after-expand="false" check-strictly=true placeholder="请选择上级菜单"></el-tree-select>
            </el-form-item>
          </div>
          <el-form-item label="菜单名称" prop='name'>
            <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入菜单名称"/>
          </el-form-item>
          <el-form-item label="权限标识" prop='permission'>
            <el-input v-model="ruleForm.permission" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入权限标识"/>
          </el-form-item>
          <el-form-item label="默认图标" prop='icon' v-if="ruleForm.type==0" @click="getIcon(true)">
            <el-input v-model="ruleForm.icon" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入默认图标" clearable>
              <template #append>
                <span :class="['icon-'+ruleForm.icon]" class="iconfont" v-if="ruleForm.icon"></span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="选中图标" prop='iconAc' v-if="ruleForm.type==0" @click="getIcon(false)">
            <el-input v-model="ruleForm.iconAc" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入选中图标" clearable>
              <template #append>
                <span :class="['icon-'+ruleForm.iconAc]" class="iconfont" v-if="ruleForm.iconAc"></span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="路由路径" prop='path' v-if="ruleForm.type==0">
            <el-input v-model="ruleForm.path" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入路由路径"/>
          </el-form-item>
          <div class="w100 dp-f fw-w">
            <el-form-item label="排序" prop='sort'>
              <el-input-number
                v-model="ruleForm.sort"
                :min="1"
                :max="999"
                controls-position="right"
                :step="1"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='180' height='40' CSStype=3 title="关闭" @buttonclick="dialogVisible = false"></oabutton>
        <oabutton class="searcML" width='180' height='40' :title="dialogVisibletitle==='新增菜单'?'添加':'修改'" CSStype=2 @buttonclick="submitForm()" v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
  <!-- 阿里云图标弹框 -->
  <Dialog ref="dialogRef" @Emit="iconEmit"></Dialog>
</template>
<script setup>
  import { reactive,ref,unref,nextTick,defineEmits } from 'vue'
  import {objdata_ret,objdata_set} from "@/utils/server/format";
  import {httpToken} from "@/utils/request";
  import qs from "qs";
  import {getmenuData,getmenuTypeData} from "@/utils/server/selectdata";
  import Dialog from "@/components/icon/dialog.vue"
  const dialogRef= ref(null);//阿里云图标弹框ref
  const dialogloading = ref(false);// 弹框按钮loading 显示
  const dialogVisible = ref(false)//弹框显示
  const dialogVisibletitle = ref(false)//弹框标题
  const emit = defineEmits(['Emit'])
  const id = ref(0);//操作的id
  const iconFlag = ref(true)
  const state = reactive({
    menuData:[],//菜单下拉数据
    menuTypeData:getmenuTypeData(),//菜单类型下拉
  })
  const ruleFormRef = ref(null);//表单ref
  const ruleForm = reactive({//from 表单数据
    name:'',//菜单名称
    icon:'',//默认菜单图标
    iconAc:'',//选中的菜单图标
    path:'',//路由路径
    type:0,//菜单类型
    parentId:'0',//上级菜单
    sort:1,//排序
    permission:'',//菜单权限标识
  })
  const rules = reactive({//from 表单校验
    name:[
      { required: true, message: '请输入菜单名称', trigger: 'blur' },
    ],
    path:[
      { required: true, message: '请输入路由路径', trigger: 'blur' },
    ],
    type:[
      { required: true, message: '请选择菜单类型', trigger: 'blur' },
    ],
    sort:[
      { required: true, message: '请选择排序', trigger: 'blur' },
    ],
    permission:[
      { required: true, message: '请输入菜单权限标识', trigger: 'blur' },
    ],
  })
  // 表单重置
  let fromDataReset=(()=>{
    objdata_ret(ruleForm)
    ruleForm.type=0
    ruleForm.sort=1
    ruleForm.parentId='0'
  })
  let add=(()=>{
    getmenuData().then(res=>{
      state.menuData=[{
        label:'根菜单',
        value:'0',
        children:res
      }]
      fromDataReset()
      dialogloading.value=false
      dialogVisibletitle.value='新增菜单'
      dialogVisible.value=true
      nextTick(()=>{
        unref(ruleFormRef).clearValidate()//清除表单验证结果
      })
    })
  })
  let editclick=((row)=>{
    getmenuData().then(res=>{
      state.menuData=[{
        label:'根菜单',
        value:'0',
        children:res
      }]
      fromDataReset()
      objdata_set(ruleForm,row)
      id.value=row.menuId
      dialogloading.value=false
      dialogVisibletitle.value='修改菜单'
      dialogVisible.value=true
      nextTick(()=>{
        unref(ruleFormRef).clearValidate()//清除表单验证结果
      })
    })
  })
  // 表单提交
  const submitForm = async () => {
    dialogloading.value=true
    const form = unref(ruleFormRef)
    if (!form) {
      dialogloading.value=false
      return
    }
    await form.validate((valid) => {
      if (valid) {
        if(dialogVisibletitle.value==='新增菜单'){
          dataGeneration(ruleForm)
        }else if(dialogVisibletitle.value==='修改菜单'){
          let ruleFormjson={
            menuId:id.value,
            ...ruleForm
          }
          // delete ruleFormjson.icon
          // delete ruleFormjson.path
          dataGeneration(ruleFormjson,'/admin/sys-menu/update')
        }
      }else{
        dialogloading.value=false
      }
    })
  }
  // 数据生成
  let dataGeneration=((json,url='/admin/sys-menu/add')=>{
    httpToken({
      method: "post",
      url: url,
      data: json,
    }).then((res) => {
      dialogloading.value=false
      dialogVisible.value=false
      emit('Emit')
    }).catch(()=>{
      dialogloading.value=false
    })
  })
  // 点击获取图片弹框
  const getIcon=((val)=>{
    iconFlag.value = val
    unref(dialogRef).show()
  })
  const iconEmit=((el)=>{
    if(iconFlag.value){
      ruleForm.icon=el
    }else{
      ruleForm.iconAc=el
    }
    
  })
  defineExpose({
    add,
    editclick,
  });
</script>