export default [
  'shouye',
  'shouye-dianliang',
  'dianziyinzhangguanli',
  'dianziyinzhangguanli-dianliang',
  'zhanghuzhongxin',
  'zhanghuxinxi-dianliang',
  'tongjiguanli',
  'tongjiguanli-dianliang',
  'jiaoseguanli',
  'jiaoseguanli-dianliang',
  'hetongwenjianguanli',
  'hetongwenjianguanli-dianliang',
  'xitongshezhi',
  'xitongshezhi-dianliang',
  'sousuo'
]
