<template>
  <el-dialog
    v-model="dialogVisible"
    title="阿里云图片"
    width="1200px"
  > 
    <div class="iconbox dp-f fw-w">
        <div class="ta-c mr-20 item mb-20 cu-p" v-for="(el,ind) in iconList" :key="ind" @click="clickIcon(el)">
            <span :class="['icon-'+el]" class="iconfont"></span>
            <div class="t-ohs mt-5">{{el}}</div>
        </div>
        <!-- {{iconList}} -->
    </div>
  </el-dialog>
</template>
<script setup>
  import { reactive,ref,unref,nextTick,defineEmits } from 'vue'
  import iconList from "@/utils/base/iconList.js"
  const dialogVisible = ref(false)//弹框显示
  const emit = defineEmits(['Emit'])
  const state = reactive({
  })
  const show=(()=>{
    dialogVisible.value=true
  })
  const clickIcon=(el=>{
    emit('Emit',el)
    dialogVisible.value=false
  })
  defineExpose({
    show
  });
</script>
<style lang="scss" scoped >
.iconbox{
    min-height: 400px;
    align-content: flex-start;
    .item{
        width: 60px;
    }
}
</style>